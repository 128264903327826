import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useLocation } from '@reach/router';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';

import {
  section,
  inner,
  imgWrap,
  omnichannelStyle,
  exStyle,
  cxStyle,
} from './styles.module.scss';

import { OCX_PROGRAM_HERO } from 'mocks/screver-ocx-program/hero';

const Hero = () => {
  const getStaticImage = useStaticImage();
  const { search } = useLocation();
  const [person, setPerson] = useState('Hey there! ');

  const urlParams = new URLSearchParams(search);

  const userName = urlParams.get('name');

  // const person = userName ? `Hey there, <u>${userName}!</u> ` : 'Hey there! ';

  const cx = useMemo(() => {
    return getImage(getStaticImage('ocx/cx.png').childImageSharp);
  }, [getStaticImage]);

  const ex = useMemo(() => {
    return getImage(getStaticImage('ocx/ex.png').childImageSharp);
  }, [getStaticImage]);

  const omnichannel = useMemo(() => {
    return getImage(getStaticImage('ocx/omnichannel.png').childImageSharp);
  }, [getStaticImage]);

  useEffect(() => {
    let name;

    name = userName ? `Hey there, <u>${userName}!</u> ` : 'Hey there! ';

    setPerson(name);
  }, [userName]);

  return (
    <section className={section}>
      <div className="ocx-prog-wrap">
        <div className={inner}>
          <span dangerouslySetInnerHTML={{ __html: person }}></span>

          <span>{OCX_PROGRAM_HERO}</span>
        </div>
      </div>

      <div className={classnames(imgWrap, 'js-ani')}>
        <GatsbyImage
          className={cxStyle}
          image={cx}
          alt={''}
          objectFit="contain"
        />
        <GatsbyImage
          className={exStyle}
          image={ex}
          alt={''}
          objectFit="contain"
        />
        <GatsbyImage
          className={omnichannelStyle}
          image={omnichannel}
          alt={''}
          objectFit="contain"
        />
      </div>
    </section>
  );
};

export default Hero;
