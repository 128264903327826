import React from 'react';

import Header from 'sections/ScreverOcxProgram/Header';
import Hero from 'sections/ScreverOcxProgram/Hero';
import PagesList from 'sections/ScreverOcxProgram/PagesList';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-ocx-program/program',
  },
  {
    title: 'Participants',
    url: '/events/screver-ocx-program/participants',
  },
  {
    title: '✌🏻Feedback',
    url: '/events/screver-ocx-program/feedback',
  },
  {
    title: 'Casino instructions',
    url: '/events/screver-ocx-program/casino-instructions',
  },
];

const seo = {
  title: 'OCX Event | Screver Feedback Management Solution',
  metaDesc:
    'Get the most out of your event by having an idea of the agenda to follow. Here, you can find a program overview helpful information and casino instructions!',
  opengraphUrl: 'https://screver.com/events/screver-ocx-program/',
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg',
};

const ScreverOcxProgram = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program">
        <Header />

        <main>
          <Hero />
          <PagesList linksToPages={PAGES} />
        </main>
      </div>
    </>
  );
};

export default ScreverOcxProgram;
